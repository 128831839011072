window.addEventListener('load', () => {
  let elementos = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, small, a, label, button, input, span');

  elementos.forEach((elemento) => {
    elemento.setAttribute('data-font-size', getComputedStyle(elemento)['font-size']);
  });
});

function alteraFonte(tipoAlteracao) {
  let nivel = parseInt(document.querySelector('.iconeFonteDefault').getAttribute('nivel'));
  let elementos = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, small, a, label, button, input, span');
  nivel = tipoAlteracao == '+' ? nivel + 1 : nivel - 1;

  let niveis = {
    0: 1,
    1: 1.1,
    2: 1.2,
    3: 1.3
  };

  // Regras para ativação e desativação de botões
  if (nivel > 0) {
    document.querySelector('.diminuirFonte').classList.remove('acessdisabled');
  } else {
    document.querySelector('.diminuirFonte').classList.add('acessdisabled');
  }

  if (nivel >= 3) {
    document.querySelector('.aumentarFonte').classList.add('acessdisabled');
  } else {
    document.querySelector('.aumentarFonte').classList.remove('acessdisabled');
  }

  // Aplicação de tamanho da fonte dentro dos elementos
  elementos.forEach((elemento) => {
    let valor = 'calc(' + elemento.getAttribute('data-font-size') + ' * ' + niveis[nivel] + ')';
    elemento.style.fontSize = valor;
  });

  // Salvamento de nível de fonte
  document.querySelector('.iconeFonteDefault').setAttribute('nivel', nivel);
}
